import { LocalizedMessagesBundle } from '../../models/i18n.model';

export const langxObj: LocalizedMessagesBundle = {
  'zh-cn': {
    'PASSWORD_STRENGTH_INFO_LENGTH_TEXT': '请输入长度【6-30】',
    'PASSWORD_STRENGTH_INFO_NUMBER_TEXT': '至少一个数字。',
    'PASSWORD_STRENGTH_INFO_LOWER_CASE_TEXT': '至少一个小写字母。',
    'PASSWORD_STRENGTH_INFO_UPPER_CASE_TEXT': '至少一个大写字母。',
    'PASSWORD_STRENGTH_INFO_SYMBOL_TEXT': '至少一个特殊字符。 (允许: #@!~%*&^)',
    'PASSWORD_STRENGTH_WEAK_TEXT': '弱',
    'PASSWORD_STRENGTH_MEDIUM_TEXT': '良好',
    'PASSWORD_STRENGTH_STRONG_TEXT': '强'
  },
  'en-gb': {
    'PASSWORD_STRENGTH_INFO_LENGTH_TEXT': 'Length: 6-30 characters',
    'PASSWORD_STRENGTH_INFO_NUMBER_TEXT': 'At least 1 number',
    'PASSWORD_STRENGTH_INFO_LOWER_CASE_TEXT': 'At least 1 lowercase',
    'PASSWORD_STRENGTH_INFO_UPPER_CASE_TEXT': 'At least 1 capital',
    'PASSWORD_STRENGTH_INFO_SYMBOL_TEXT': 'At least 1 special symbol (Allow: #@!~%*&^)',
    'PASSWORD_STRENGTH_WEAK_TEXT': 'Weak',
    'PASSWORD_STRENGTH_MEDIUM_TEXT': 'Medium',
    'PASSWORD_STRENGTH_STRONG_TEXT': 'Strong'
  }
}