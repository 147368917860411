import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import {
  ForgotPasswordChangePasswordDto,
  ForgotPasswordSendOtpDto,
  ForgotPasswordUserVerificationResponse,
  ForgotPasswordVerifyDto,
  ForgotPasswordVerifyOtpDto
} from '../../models/forgot-password.model';
import { BaseResponse } from '../../models/http.model';
import { LoginDTO, LoginResponse } from '../../models/login.model';
import { RegisterWithWellbetDTO } from '../../models/register-with-wellbet.model';
import { RegisterDTO } from '../../models/register.model';
import { UserProfile } from '../../models/user.model';
import { CookieService } from '../cookie/cookie.service';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private bannerViewToggle = new BehaviorSubject<boolean>(true);
  bannerViewToggle$ = this.bannerViewToggle.asObservable();

  private currentUser = new ReplaySubject<UserProfile>();
  currentUser$ = this.currentUser.asObservable();

  constructor(
    private httpService: HttpService,
    private cookieService: CookieService) {
  }

  register(registerDto: RegisterDTO): Observable<BaseResponse> {
    return this.httpService.forClientApi().post('/user/register', registerDto);
  }

  isLoginNameAvailable(loginName: string, verificationStub: string): Observable<BaseResponse> {
    const url = '/user/login-name/' + loginName + '?verificationStub=' + verificationStub;
    return this.httpService.forClientApi().get(url);
  }

  isLoginNameTaken(registerWithWellbetDTO: RegisterWithWellbetDTO): Observable<BaseResponse> {
    return this.httpService.forWellbetService().post('/zeta/registration/login.wb', registerWithWellbetDTO);
  }

  registerWellbetAccount(registerWithWellbetDTO: RegisterWithWellbetDTO): Observable<BaseResponse> {
    return this.httpService.forClientApi().post('/user/register/wellbet', registerWithWellbetDTO);
  }
  /**
   * Hides the upper part of the page. 
   * Useful when we want a blank white page
   */
  hidePageBanner(): void {
    this.bannerViewToggle.next(false);
  }

  /**
   * Shows the upper part of the page. 
   */
  showPageBanner(): void {
    this.bannerViewToggle.next(true);
  }

  login(loginDto: LoginDTO): Observable<BaseResponse<LoginResponse>> {
    return this.httpService.forClientApi().post('/user/login', loginDto);
  }

  fetchForgotPasswordVerificationCodeImage(): Observable<BaseResponse<any>> {
    return this.httpService.forClientApi().get('/user/forgot-password/code');
  }

  validateForgotPasswordUserVerification(forgotPasswordDto: ForgotPasswordVerifyDto)
    : Observable<BaseResponse<ForgotPasswordUserVerificationResponse>> {
    return this.httpService.forClientApi().post('/user/forgot-password/verify', forgotPasswordDto);
  }

  sendForgotPasswordOtp(forgotPasswordOtpDto: ForgotPasswordSendOtpDto): Observable<BaseResponse<any>> {
    return this.httpService.forIntegrationService().post('/user/forgot-password/otp/send', forgotPasswordOtpDto);
  }

  validateForgotPasswordOtp(forgotPasswordVerifyOtpDto: ForgotPasswordVerifyOtpDto): Observable<BaseResponse<any>> {
    return this.httpService.forClientApi().post('/otp/validate/forgot-password', forgotPasswordVerifyOtpDto);
  }

  changePassword(forgotPasswordChangePasswordDto: ForgotPasswordChangePasswordDto): Observable<BaseResponse<any>> {
    return this.httpService.forClientApi()
      .post('/user/forgot-password/password/change', forgotPasswordChangePasswordDto);
  }

  setCurrentUser(user: UserProfile) {
    this.currentUser.next(user);
    this.cookieService.setCurrentUserLoginName(user.loginName)
  }

  resolveCurrentUserFromCookies() {
    this.currentUser.next({
      loginName: this.cookieService.getCurrentUserLoginName()
    });
  }

  syncPlayerInfo() {
    this.httpService.forIntegrationService().get('/user/stream/player/sync').subscribe();
  }
}
