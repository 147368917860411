import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { CookieService } from '../cookie/cookie.service';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private httpService: HttpService,
    private cookieService: CookieService
  ) { }

  checkSession() {
    return this.httpService.forClientApi().get('/authorization/refresh?t=' + new Date().getTime());
  }

  saveAccessToken(accessToken: string) {
    this.cookieService.setAccessToken(accessToken);
  }

  deleteAccessToken() {
    this.cookieService.deleteAccessToken();
  }

  getAccessToken(): string {
    return this.cookieService.getAccessToken();
  }

  saveHiggsJwt(higgsJwt: string) {
    this.cookieService.setHiggsJwt(higgsJwt);
  }

  getHiggsJwt() {
    return this.cookieService.getHiggsJwt();
  }

  logout() {
    return this.httpService.forClientApi().post('/user/logout', '').pipe(
      tap(() => this.cookieService.deleteAccessToken())
    );
  }

  setScanAccessToken(accessToken: string) {
    this.cookieService.setScanAccessToken(accessToken);
  }

  getScanAccessToken() {
    return this.cookieService.getScanAccessToken();
  }

  resetAccessTokenExpiration(): void {
    const accessToken = this.getAccessToken();
    if (accessToken) {
      this.saveAccessToken(accessToken);
    }
  }
  
}
