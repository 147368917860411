@if(show) {

    @if(isNoStrength()) {
        <span class="password-strength-gray-bar"></span>
        <span class="password-strength-gray-bar"></span>
        <span class="password-strength-gray-bar"></span>
        <span class="password-strength-bar-text text-center">{{langx['PASSWORD_STRENGTH_WEAK_TEXT']}}</span>
    }

    @if(isWeak()){
        <span class="password-strength-muted-bar"></span>
        <span class="password-strength-gray-bar"></span>
        <span class="password-strength-gray-bar"></span>
        <span class="password-strength-bar-text text-center">{{langx['PASSWORD_STRENGTH_WEAK_TEXT']}}</span>
    }

    @if(isMedium()){
        <span class="password-strength-muted-bar"></span>
        <span class="password-strength-muted-bar"></span>
        <span class="password-strength-gray-bar"></span>
        <span class="password-strength-bar-text text-center">{{langx['PASSWORD_STRENGTH_MEDIUM_TEXT']}}</span>
    }

    @if(isStrong()){
        <span class="password-strength-success-bar"></span>
        <span class="password-strength-success-bar"></span>
        <span class="password-strength-success-bar"></span>
        <span class="password-strength-bar-text text-center">{{langx['PASSWORD_STRENGTH_STRONG_TEXT']}}</span>
    }

    @if(!isValidLength()){
        <div class="password-strength-field-error text-smaller">
            <span class="material-symbols-outlined">cancel</span> {{langx['PASSWORD_STRENGTH_INFO_LENGTH_TEXT']}}
        </div>
    } @else {
        <div class="password-strength-field-success text-smaller">
            <span class="material-symbols-outlined">check_circle</span> {{langx['PASSWORD_STRENGTH_INFO_LENGTH_TEXT']}}
        </div>
    }
    
    @if(!hasNumber()){
        <div class="password-strength-field-error text-smaller">
            <span class="material-symbols-outlined">cancel</span> {{langx['PASSWORD_STRENGTH_INFO_NUMBER_TEXT']}}
        </div>
    } @else {
        <div class="password-strength-field-success text-smaller">
            <span class="material-symbols-outlined">check_circle</span> {{langx['PASSWORD_STRENGTH_INFO_NUMBER_TEXT']}}
        </div>
    }

    @if(!hasLowerCase()){
        <div class="password-strength-field-error text-smaller">
            <span class="material-symbols-outlined">cancel</span> {{langx['PASSWORD_STRENGTH_INFO_LOWER_CASE_TEXT']}}
        </div>
    } @else {
        <div class="password-strength-field-success text-smaller">
            <span class="material-symbols-outlined">check_circle</span> {{langx['PASSWORD_STRENGTH_INFO_LOWER_CASE_TEXT']}}
        </div>
    }

    @if(!hasUpperCase()){
        <div class="password-strength-field-error text-smaller">
            <span class="material-symbols-outlined">cancel</span> {{langx['PASSWORD_STRENGTH_INFO_UPPER_CASE_TEXT']}}
        </div>
    } @else {
        <div class="password-strength-field-success text-smaller">
            <span class="material-symbols-outlined">check_circle</span> {{langx['PASSWORD_STRENGTH_INFO_UPPER_CASE_TEXT']}}
        </div>
    }

    @if(!hasSpecialSymbols()){
        <div class="password-strength-field-error text-smaller">
            <span class="material-symbols-outlined">cancel</span> {{langx['PASSWORD_STRENGTH_INFO_SYMBOL_TEXT']}}
        </div>
    } @else {
        <div class="password-strength-field-success text-smaller">
            <span class="material-symbols-outlined">check_circle</span> {{langx['PASSWORD_STRENGTH_INFO_SYMBOL_TEXT']}}
        </div>
    }

}