import { Component, Input, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { LocalizedMessages } from '../../models/i18n.model';
import { CommonService } from '../../service/common/common.service';
import { langxObj } from './password-strength_langx';

@Component({
  selector: 'app-password-strength',
  templateUrl: './password-strength.component.html',
  styleUrl: './password-strength.component.scss'
})
export class PasswordStrengthComponent implements OnDestroy {

  @Input() password: string = '';
  @Input() show: boolean = false;

  private destroy$ = new Subject<void>();
  currentLanguage: string = '';
  langx: LocalizedMessages = {};

  constructor(private commonService: CommonService){
    this.commonService.currentLanguage$
      .pipe(takeUntil(this.destroy$))
      .subscribe(currentLanguage => {
        this.currentLanguage = currentLanguage;
        this.langx = langxObj[this.currentLanguage];
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  isValidLength(): boolean{
    if(this.isPasswordEmpty()) {
      return false;
    }

    return this.password.length >= 6 && this.password.length <= 30;
  }

  hasNumber(): boolean{
    return /\d/.test(this.password);
  }

  hasLowerCase() {
    return !this.isPasswordEmpty() && /[a-z]/.test(this.password);
  }

  hasUpperCase(): boolean {
    return /[A-Z]/.test(this.password);
  }

  hasSpecialSymbols(): boolean{
    const invalidSymbolsRegex = /[^a-zA-Z0-9#@!~%*&^]/;
    if (invalidSymbolsRegex.test(this.password)) {
      return false;
    }

    const allowedSymbolsRegex = /[#@!~%*&^]/;
    return allowedSymbolsRegex.test(this.password);
  }

  isPasswordEmpty(): boolean{
    return this.password == null || this.password.length < 1;
  }

  getPasswordStrength(): number {
    const conditionsMet = [
      this.isValidLength(),
      this.hasNumber(),
      this.hasLowerCase(),
      this.hasUpperCase(),
      this.hasSpecialSymbols(),
    ];
    return conditionsMet.filter(Boolean).length;
  }

  isNoStrength() {
    return this.getPasswordStrength() == 0;
  }

  isWeak(): boolean {
    const strength = this.getPasswordStrength();
    return strength >= 1 && strength <= 2;
  }

  isMedium(): boolean {
    const strength = this.getPasswordStrength();
    return strength >= 3 && strength <= 4;
  }

  isStrong(): boolean {
    return this.getPasswordStrength() == 5;
  }

}
