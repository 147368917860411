import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Domains } from '../../models/domains.model';
import { BaseResponse } from '../../models/http.model';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  
  httpOptions: {headers: HttpHeaders} = {
    headers: new HttpHeaders({ 
      'Accept-Language': 'zh-cn'
    })
  };

  private language = '';

  private domains: Domains = {
    clientApiService: '',
    integrationService: '',
    wellbetService: '',
    mediaServer: '',
    higgsServer: ''
  };

  constructor(private httpClient: HttpClient) {}

  setDomains(domains: Domains) {
    this.domains = domains;
  }

  setLanguage(language: string) {
    this.language = language;
  }

  getLanguage(): string {
    return this.language;
  }

  forClientApi() {
    return new HttpOperations(this.httpClient, this.domains.clientApiService, this.httpOptions);
  }

  forIntegrationService() {
    return new HttpOperations(this.httpClient, this.domains.integrationService, this.httpOptions);
  }

  forWellbetService() {
    return new HttpOperations(this.httpClient, this.domains.wellbetService, this.httpOptions);
  }

  forHiggsServer() {
    return new HttpOperations(this.httpClient, `https://${this.domains.higgsServer}`, this.httpOptions);
  }

  forEmptyBaseUrl() {
    return new HttpOperations(this.httpClient, '', this.httpOptions);
  }
}

export class HttpOperations {
  private ERROR_UNAUTHORIZED_STATUS_CODE: number = 401;

  constructor(
    private httpClient: HttpClient, 
    private baseUrl: string, 
    private httpOptions: { headers: HttpHeaders }
  ) { }

  get(url: string): Observable<BaseResponse> {
    return this.httpClient.get<BaseResponse>(this.baseUrl + url, this.httpOptions).pipe(
      catchError(this.handleError<BaseResponse>(url))
    );
  }

  post(url: string, param: any): Observable<BaseResponse> {
    return this.httpClient.post<BaseResponse>(this.baseUrl + url, param, this.httpOptions).pipe(
      catchError(this.handleError<BaseResponse>(url))
    )
  }

  put(url: string, param: any) {
    return this.httpClient.put<BaseResponse>(this.baseUrl + url, param, this.httpOptions).pipe(
      catchError(this.handleError<BaseResponse>(url))
    )
  }

  delete(url: string) {
    return this.httpClient.delete<BaseResponse>(this.baseUrl + url, this.httpOptions).pipe(
      catchError(this.handleError<BaseResponse>(url))
    )
  }

  private handleError<T>(operation = 'operation') {
    return (error: any): Observable<T> => {
      operation.toString();

      // Return an empty result, since the error unauthorized is already handled in the HTTP interceptor.
      if(error.status == this.ERROR_UNAUTHORIZED_STATUS_CODE) {
        return of();
      }

      // Let the app keep running by returning an empty result.
      return of(error.error);
    };
  }

} 
